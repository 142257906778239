body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  overflow-x: hidden;
}
body {
  overflow: hidden;
  padding: 0px !important;
}

.mailTo {
  cursor: pointer;

  &:hover {
      color: rgb(6,69,173);
  }
  &:active {
      color: rgb(102,51,102)
  }
}

.MuiButton-containedSecondary {
  color: rgba(255,255,255,1) !important;
  --shadowColor: rgb(0,0,0,0.05);
  text-shadow: var(--shadowColor) 1px 0px 1px, var(--shadowColor) 0.540302px 0.841471px 1px, var(--shadowColor) -0.416147px 0.909297px 1px, var(--shadowColor) -0.989992px 0.14112px 1px, var(--shadowColor) -0.653644px -0.756802px 1px, var(--shadowColor) 0.283662px -0.958924px 1px, var(--shadowColor) 0.96017px -0.279415px 1px;
}
.MidShortLine {
  position: absolute;
  left: 50%;
  top: 100%;
  height: 0.5px;
  width: 16.5rem;

  transform: translate(-50%,0);
  border-radius: 50%;

  background-color: white;

  filter: drop-shadow(0px 1px 1px rgba(255,255,255,0.25)) blur(1px) ;
}

@media only screen and (max-width: 960px) {
  html {
    font-size: 16px;
  }
}
@media only screen and (max-width: 660px) {
  html {
    font-size: 12px;
  }
}
@media only screen and (max-width: 460px) {
  html {
    font-size: 12px;
  }
}