/*
[class*='-colorPrimary'] {
  background-color: rgba(var(--color-primary),1) !important;
}
[class*='-colorSecondary'] {
  background-color: rgba(var(--color-secondary),1) !important;
}
[class*='-textPrimary'] {
  color: rgba(var(--color-textPrimary),1) !important;
}
[class*='-textSecondary'] {
  color: rgba(var(--color-textSecondary),1) !important;
}

[class*='containedPrimary'] {
  background-color: rgba(var(--color-primary),1) !important;
  color: rgba(var(--color-textSecondary),1) !important;
}

[class*='MuiTypography-colorPrimary'] {
  background-color: transparent !important;
  color: rgba(var(--color-primary),1) !important;
}

[class*='textWhite'] {
  color: rgba(var(--color-textWhite),1) !important;
}
*/

.OuterContaner {
  display: grid;
  min-height: calc(100vh - 64px);
}
[class$="Page"] {
  
  max-width: 100vw;
}