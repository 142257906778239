.MyHeader {
    padding :0px !important;
    color: rgba(255,255,255,1) !important;
    &.triggered {
        color: rgba(0,0,0,1) !important;
    }

    .MuiButton-colorInherit {
        color: rgba(255,255,255,1) !important;
    }
    .logo {
        transition: all 0.1s ease-in-out;
        display: inline-block;
        
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    overflow: hidden;
}