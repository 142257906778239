.sociallinks {
    svg {
        margin: 0px 10px;
        color: white;
        fill: white;
        opacity: 0.5;
        cursor: pointer;
        transition: opacity 0.15s linear;

        &:hover {
            opacity: 1;
        }
        &:active {
            filter: drop-shadow( 0px 0px 2px rgba(255, 255, 255, .7));
        }
    }
}