.HomeLoadingPage {
    .sticky-image-placeholder {
        height: 80vh;
        background-color: rgba(192,192,192,1);
    }
    
    .homePageHello {
        margin-top: 15px !important;
        font-size: 22px !important;
        margin-bottom: 16px !important;
    }
}


@media only screen and (max-width: 960px) {
    .HomeLoadingPage {
        
        .homePageHello {
            margin-top: -8.5px !important;
            margin-bottom: 30px !important;

            h1 {
                font-size: 22px !important;
            }
            h2 {
                font-size: 12px !important;
            }
        }
    }
    
}